import * as React from 'react';

import Layout from '~/components/layout';
import Seo from '~/components/seo';

import * as Styled from '../styling/styles';

interface OnlineLinks {
  readonly name: string;
  readonly address: string;
}

interface Props {
  text?: string;
  cursor?: boolean;
}

const About = () => {
  const links: OnlineLinks[] = [
    {
      name: 'LinkedIn/',
      address: 'https://www.linkedin.com/in/chuka-nwobi-8ab2821ab/',
    },
    {
      name: 'Github/',
      address: 'https://github.com/cnwobi',
    },
  ];

  const PWD = ({ text, cursor }: Props) => (
    <Styled.H1>
      <span>chukanwobi.com ~ %</span> {text}{' '}
      {cursor && <Styled.Cursor>&nbsp;</Styled.Cursor>}
    </Styled.H1>
  );
  return (
    <Layout>
      <Seo title="About" />
      <Styled.About>
        <div style={{ width: '100%' }}>
          <PWD text={'ls nav/'} />
          <Styled.Nav>
            <ul>
              <li>about/</li>
              <li>contact/</li>
            </ul>
          </Styled.Nav>
          <PWD text={'ls find-me-online/'} />
          <Styled.OnlineLink>
            {links.map(({ name, address }) => (
              <li key={name}>
                <a href={address} target={'_blank'} rel="noreferrer">
                  {name}
                </a>
              </li>
            ))}
          </Styled.OnlineLink>
          <PWD cursor={true} />
        </div>
      </Styled.About>
    </Layout>
  );
};
export default About;
